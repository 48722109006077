import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Container from '@components/Container'
import Hero from '@components/Hero'
import Footer from '@components/Footer'
import PageComponents from '@components/PageComponents'
import SEO from '@components/partials/SEO'
import { metaData } from '@fragments/meta'

export const ServiceTemplate = ({ hero, components, slug, page }) => {
  return (
    <Container className={`service service--${slug}`} page={page}>
      <Hero {...hero} />
      <main>
        <article>
          <PageComponents components={components} />
        </article>
      </main>
      <Footer />
    </Container>
  )
}

ServiceTemplate.propTypes = {
  hero: PropTypes.object,
  components: PropTypes.array,
  slug: PropTypes.string,
}

const Service = ({ data }) => {
  const {
    wordpressWpService: { yoast_meta: meta, ...service },
  } = data

  if (service.acf.hero && !service.acf.hero.heading) {
    service.acf.hero.heading = service.title
    service.acf.hero.type = 'basic'
  }

  return (
    <>
      <SEO {...meta} path={service.path} />
      <ServiceTemplate
        hero={service.acf.hero}
        components={service.acf.content_service}
        slug={service.slug}
        page={service}
      />
    </>
  )
}

Service.propTypes = {
  data: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query ServiceByID($id: String!) {
    wordpressWpService(id: { eq: $id }) {
      title
      slug
      path
      acf {
        hero {
          ...HeroFragment
        }
        content_service {
          __typename
          ...AccordionBlockComponentFragment
          ...BannerComponentFragment
          ...CardsComponentFragment
          ...FeaturedProjectsComponentFragment
          ...FormComponentFragment
          ...ImageComponentFragment
          ...ImageBlocksComponentFragment
          ...LogoGridComponentFragment
          ...ListBlockComponentFragment
          ...SliderComponentFragment
          ...TeamComponentFragment
          ...TestimonialsComponentFragment
          ...TextComponentFragment
          ...TextWithImagesFragment
          ...TilesComponentFragment
        }
      }
      yoast_meta {
        ...metaData
      }
    }
  }
`

export default Service
